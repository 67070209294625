.SpacesLogoParent-PC {
  display: flex;
  position: relative;
  width: 220px;
  height: 28px;
  margin-right: 5px;
  margin-top: 2px;
  align-self: flex-start;
  justify-content: flex-end;
}

.SpacesLogoChild1-PC {
  display: flex;
  position: absolute;
  align-self: center;
  font-family: 'Press Start 2p';
  font-weight: normal;
  font-size: 18px;
  color: #ffd700;
  z-index: 2;
  left: 0;
  bottom: 0;
}

.SpacesLogoChild2-PC {
  display: flex;
  position: absolute;
  align-self: center;
  font-family: 'Press Start 2p';
  font-weight: normal;
  font-size: 18px;
  color: #7741ed;
  z-index: 1;
  left: 4px; /* Ensure units are specified */
  bottom: 0;
}


.PlayFairLogoParent-PC {
  display: flex;
  position: relative;
  width: 110px;
  height: 28px;
  margin-right: 5px;
  margin-top: 18px;
  align-self: flex-start;
}

.PlayFairLogoChild1-PC {
  display: flex;
  position: absolute;
  align-self: center;
  font-family: 'Press Start 2p';
  font-weight: normal;
  font-size: 9px;
  color: #dae011;
  z-index: 2;
  left: 0;
  bottom: 0;
}

.PlayFairLogoChild2-PC {
  display: flex;
  position: absolute;
  align-self: center;
  font-family: 'Press Start 2p';
  font-weight: normal;
  font-size: 9px;
  color: #7741ed;
  z-index: 1;
  left: 2px; /* Ensure units are specified */
  bottom: 0;
}

