/* LoadingIcon.css */
.loading-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  .spinner {
    width: 20px;
    height: 20px;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid rgba(0,0,0,0.8);
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  