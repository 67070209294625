.App-PC {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  background-color: #191a39;
  min-height: 100vh;
  width: 100vw;
}

.Game-PC{
  display: flex;
  flex-direction: column;
  width: 95%;
  height: 740px;
  margin-top: 10px;
  border: 2px solid #090c1d; 
  border-radius: 40px;
  align-self: center;
  overflow: hidden;
}

.Toss-History-PC{
  display: flex;
  flex-direction: column;
  height: 500px;
  width: 95%;
  margin-top: 20px;
  border: 2px solid #090c1d; 
  background-color: #090c1d;
  justify-content: flex-start;
  border-radius: 40px;
  padding-bottom: 20px;
  align-self: center;
  overflow: hidden;
  margin-bottom: 20px;
}

.AppHeader-PC{
  display: flex;
  width: 90%;
  height: 50px;
  align-self: center;
  flex-direction: row;
  margin-top: 30px;
  justify-content: space-between;
}

.rounded-input {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; /* Adjust width as needed */
  max-width: 150px; /* Maximum width for the input */
  height: 45px; /* Height of the input */
  border: 2px solid ; 
  border-radius: 25px; /* Rounded corners */
  padding: 0 15px; /* Horizontal padding */
  font-family: 'Comfortaa';
  font-weight:bold;
  font-size: 20px; /* Font size */
  color: #dae011; /* Text color */
  background-color: #1d1d1d;
  margin-top: 15px;
  outline: none; /* Remove outline on focus */
  transition: border-color 0.3s; /* Smooth transition for border color */
  text-align: center;
}

.rounded-input::-webkit-inner-spin-button,
.rounded-input::-webkit-outer-spin-button {
  -webkit-appearance: none; /* Remove default styles */
  margin: 0; /* Remove margin */
}

.rounded-input::placeholder {
  color: #7a7a7a; /* Set your desired placeholder color */
  opacity: 0.5; /* Optional: set opacity for better visibility */
}

.rounded-input:focus {
  border-color: #dae011; /* Change border color on focus */
}

.scroll-container {
  position: relative;
  width: 300px; /* Width of the scrollbar */
  height: 60px; /* Height of the scrollbar */
  margin-top: 25px;
}

.scrollbar {
  width: 100%;
  height: 20px; /* Height of the scrollbar */
  background: linear-gradient(to right, #00ff00, #ffff00, #ffa500, #ff0000); /* Gradient from green to red */
  border-radius: 10px; /* Rounded corners */
  position: relative;
  cursor: pointer; /* Cursor style */
}

.scroll-thumb {
  position: absolute;
  width: 30px; /* Width of the thumb */
  height: 30px; /* Height of the thumb */
  background: #73d2e5; /* Dark grey color */
  border-radius: 50%; /* Circular handle */
  top: -5px; /* Center the thumb vertically */
  transition: background 0.3s; /* Smooth transition for hover effect */
  border: 2px solid rgba(0, 0, 0, 1); 
}

.scroll-thumb:hover {
  background: #2ea7bf; /* Slightly darker grey on hover */
}

.ratio-text {
  position: absolute;
  top: 30px; /* Position below the scrollbar */
  left: 0; /* Initial left position */
  transform: translateX(-50%); /* Center the text below the thumb */
  text-align: center;
  font-family: 'Comfortaa';
  font-weight: bold;
  font-size: 16px; /* Initial font size */
  margin-left: 18px;
  margin-top: 3px;
  color: #73d2e5; /* Text color */
  transition: font-size 0.2s; /* Smooth transition for font size */
}

.slider-container {
  position: relative;
  width: 250px; /* Width of the slider */
  height: 50px; /* Height of the slider */
  display: flex;
  border-radius: 25px; /* Rounded corners */
  background-color: #090c1d; /* Default background color */
  cursor: pointer; /* Change cursor on hover */
}

.option {
  flex: 1; /* Each option takes equal space */
  display: flex;
  margin:4px;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s; /* Smooth transition for background color */
  border-radius: 25px; /* Rounded corners */
  color: white; /* Default text color */
  font-family: 'Comfortaa';
  font-weight: bolder;
  font-size: 16px;
}

.option.active {
  color: white; /* Text color for active option */
  background-color: #04ff00; /* Bright color for the active option */
}

.option:hover {
  background-color: #191a39; /* Highlight color on hover */
}

.slider {
  display: flex;
  flex-direction: row;
  position: absolute;
  width: 121px; /* Width of the slider */
  height: 42px; /* Full height */
  margin:4px;
  justify-content: center;
  border-radius: 25px; /* Rounded corners */
  transition: transform 0.3s ease, background 0.3s ease; /* Smooth transition for slider movement and background color */
  color: white; /* Default text color */
}

.slider-active {
  transform: translateX(0); /* Position for the first option */
  background: #7741ed; /* Green color for the first option */
}

.slider-inactive {
  transform: translateX(100%); /* Position for the second option */
  background: #6dc129; /* Red color for the second option */
}

.selected-text {
  display: flex;
  color: white; /* Text color for visibility */
  font-family: 'Comfortaa';
  font-weight: bolder;
  align-self: center;
  font-size: 16px;
}


.game-info-button {
  display: flex;
  flex-direction: column;
  width:50px;
  height:50px;
  border-radius: 25px;
  justify-content: center;
  transition: background-color 0.3s ease;
  font-family: 'Comfortaa';
  font-weight: bold;
  color: white;
  font-size: 18px;
  border: none;
  background-color: #090c1d;
  cursor: pointer;
}

.game-info-button-text {
  display:flex;
align-self: center;
}

.game-info-button:hover {
  background-color: #6a6aa8; /* Brighter color on hover */
}

.super-chat-button {
  display: flex;
  flex-direction: column;
  width:150px;
  height:50px;
  border-radius: 25px;
  justify-content: center;
  transition: background-color 0.3s ease;
  font-family: 'Comfortaa';
  font-weight: bold;
  color: white;
  font-size: 18px;
  border: none;
  background-color: #090c1d;
  cursor: pointer;
}

.super-chat-button-text {
  display:flex;
align-self: center;
}

.super-chat-button:hover {
  background-color: #6a6aa8; /* Brighter color on hover */
}



.deposit-back-button {
  background-color:#090c1d;
  cursor: pointer;
}

.deposit-back-button:hover {
  background-color: #6a6aa8; /* Brighter color on hover */
}


.bet-input-1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height:50px;
  margin-top: 6px;
  margin-left: 10px;
  width: 180px;
  align-self: center;
  background-color: #090c1d;
  border: 3px solid #191a39 ;
  border-radius: 30px; /* Increased border radius */
}

.dollar-icon-1 {
  width: 32px;
  height: 32px;
  margin-left: 10px;
  border-radius: 25px;
  align-self: center;
}

.input-field-1 {
  width: 120px;
  background-color: #090c1d;
  color: white;
  border: none;
  border-radius: 25px; /* Increased border radius */
  text-align: start;
  padding-left: 10px;
  font-family: 'Comfortaa';
  font-weight: bold;
  font-size: 20px;
  outline: none; /* Remove outline on focus */
}

.input-field-1::-webkit-inner-spin-button,
.input-field-1::-webkit-outer-spin-button {
  -webkit-appearance: none; /* Remove default styles */
  margin: 0; /* Remove margin */
}


.your-history-button {
  display: flex;
  flex-direction: column;
  width:120px;
  height:40px;
  border-radius: 25px;
  justify-content: center;
  transition: background-color 0.3s ease;
  font-family: 'Comfortaa';
  font-weight: bold;
  color: white;
  font-size: 15px;
  border: none;
  background-color: #090c1d;
  cursor: pointer;
  align-self: center;
}

.your-history-button-text {
  display:flex;
align-self: center;
}

.your-history-button:hover {
  background-color: #6a6aa8; /* Brighter color on hover */
}

.your-history-button.active {
  background-color: #7741ed; 
}


.global-button {
  display: flex;
  flex-direction: column;
  width:120px;
  height:40px;
  border-radius: 25px;
  justify-content: center;
  transition: background-color 0.3s ease;
  font-family: 'Comfortaa';
  font-weight: bold;
  color: white;
  font-size: 15px;
  border: none;
  background-color: #090c1d;
  cursor: pointer;
  align-self: center;
}

.global-button-text {
  display:flex;
  align-self: center;
}

.global-button:hover {
  background-color: #6a6aa8; /* Brighter color on hover */
}

.global-button.active {
  background-color: #7741ed; 
}

.scroll-container {
  height: 400px; /* Set the height of the scrollable area */
  width: 92%;
  align-self: center;
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden; /* Disable horizontal scrolling */
  padding-right: 8px;
  margin-left: 10px;
}

/* For WebKit-based browsers (Chrome, Safari, Edge) */
.scroll-container::-webkit-scrollbar {
  width: 14px; /* Adjust the width of the scrollbar */
  background-color: #191a39; /* Background color of the scrollbar track */
  border-radius: 10px; /* Rounded edges for the scrollbar */
}

.scroll-container::-webkit-scrollbar-thumb {
  background-color: #7741ed;; /* Color of the scrollbar handle */
  border-radius: 10px; /* Rounded edges for the scrollbar handle */
  width:8px;
}

/* Remove the top and bottom arrows */
.scroll-container::-webkit-scrollbar-button {
  display: none;
}

/* Optional: Hide scrollbar when not in use */
.scroll-container::-webkit-scrollbar-track-piece {
  background-color: #191a39; /* Background color of the scrollbar track */
  border-radius: 10px; /* Rounded edges for the scrollbar track */
  width:8px;
}

.list-title {
  display: flex;
  flex-direction: row;
  width:18%;
  height:40px;
  border-radius: 25px;
  justify-content: center;
  transition: background-color 0.3s ease;
  font-family: 'Comfortaa';
  font-weight: bold;
  color: white;
  font-size: 15px;
  border: none;
  background-color: #090c1d;
  align-self: center;
  justify-content: center;
}

.list-item {
  display: flex;
  flex-direction: row;
  width:18%;
  height:40px;
  border-radius: 25px;
  justify-content: center;
  transition: background-color 0.3s ease;
  font-family: 'Comfortaa';
  font-weight: bold;
  color: white;
  font-size: 15px;
  border: none;
  background-color: #191a39;
  align-self: center;
  justify-content: center;
}

.list-item.positive {
  background-color: #6dc129;
}

.list-item.negative {
  background-color: #fa4a4a;
}

.scroll-list-item-text {
  display: flex;
  width: 80%;
  align-self: center;
  justify-content: center; /* Center the text horizontally */
  overflow: hidden; /* Hide overflowing text */
  text-overflow: ellipsis; /* Add ellipsis to truncated text */
  text-align: center;
  align-items: center; /* Vertically center the text */
}

.scroll-list-item-address-text {
  width: 80%;
  align-self: center;
  justify-content: center; /* Center the text horizontally */
  overflow: hidden; /* Hide overflowing text */
  text-align: start;
  text-overflow: ellipsis;
}






