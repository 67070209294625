.app {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #090c1d;
    color: white;
    font-family: Arial, sans-serif;
    height: 100%;
    width: 100%;
  }
  
  
  .game-image {
    width: 100%;
    height: 70%;
    background-color: #161b33;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .game-image img {
    max-width: 100%;
    max-height: 100%;
  }
  
  .controls {
    width:100%;
    padding-top: 20px;
    background-color: #090c1d;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .bet-amount, .difficulty, .play-style {
    margin-bottom: 20px;
    margin-left: 25px;
    margin-right: 25px;
  }

  .bet-amount {
    flex:1;
  }
  
  .difficulty {
    flex:1;
  }

  .bet-header, .difficulty-header,  .toss-style-header{
    font-size: 18px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    font-family: 'Comfortaa';
    font-weight: bold;
    font-size: 16px;
    margin-left: 10px;
  }

  /* CSS for the fading effect */
.fade {
  opacity: 0;
  transition: opacity 0.5s ease-in-out; /* 0.5 seconds for fade-in/fade-out */
}

.fade.show {
  opacity: 1;
}

/* Custom styles for the "max possible bet" text */
.max-bet {
  font-size: 14px; /* Adjust the font size */
  color: #b69af3;  /* Customize the color, e.g., tomato red */
  font-weight: normal; /* Optional: Adjust weight if needed */
  margin-left: 25px;
}

  .info-button {
    padding-top: 4px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;
  }
  
  .difficulty-header .info-button {
    margin-left: 10px;
    font-size: 16px;
    background-color: #191a39;
    color: white;
    border: none;
    border-radius: 12px; /* Increased border radius */
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-family: 'Comfortaa';
    font-weight: bold;
    font-size: 14px;
  }
  
  .difficulty-header .info-button:hover {
    background-color: #6a6aa8; /* Brighter color on hover */
  }
  


  .toss-style-header .info-button {
    margin-left: 10px;
    font-size: 16px;
    background-color: #191a39;
    color: white;
    border: none;
    border-radius: 12px; /* Increased border radius */
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-family: 'Comfortaa';
    font-weight: bold;
    font-size: 14px;
  }
  
  .toss-style-header .info-button:hover {
    background-color: #6a6aa8; /* Brighter color on hover */
  }
  
  
  .bet-input {
    display: flex;
    align-items: center;
    background-color: #090c1d;
    border: 3px solid #191a39 ;
    border-radius: 16px; /* Increased border radius */
  }
  
  .dollar-icon {
    width: 32px;
    height: 32px;
    margin-right: 10px;
    margin-left: 10px;
  }
  
  .input-field {
    width: 80%;
    background-color: #090c1d;
    color: white;
    border: none;
    border-radius: 12px; /* Increased border radius */
    text-align: center;
    font-family: 'Comfortaa';
    font-weight: bold;
    font-size: 20px;
    outline: none; /* Remove outline on focus */
  }

.input-field::-webkit-inner-spin-button,
.input-field::-webkit-outer-spin-button {
  -webkit-appearance: none; /* Remove default styles */
  margin: 0; /* Remove margin */
}

.input-field:focus {
    border-color: none; /* Change border color on focus */
  }

  
  .bet-input button {
    margin-left: 2.5px;
    margin-right: 3px;
    margin-top: 4px;
    margin-bottom: 4px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #191a39;
    color: white;
    border: none;
    border-radius: 10px; /* Increased border radius */
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-family: 'Comfortaa';
    font-weight: bold;
    font-size: 14px;
  }
  
  .bet-input button:hover {
    background-color: #6a6aa8; /* Brighter color on hover */
  }
  
  
  .difficulty-buttons {
    display: flex;
    justify-content: space-around;
    width: 100%;
    border: 3px solid #191a39 ;
    border-radius: 18px;
    padding: 2px;
  }
  
  .difficulty-button {
    flex: 1;
    margin-left: 2.5px;
    margin-right: 2.5px;
    margin-top: 2px;
    margin-bottom: 2px;
    padding: 10px;
    background-color: #191a39;
    color: white;
    border: none;
    border-radius: 12px; /* Increased border radius */
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-family: 'Comfortaa';
    font-weight: bold;
    font-size: 14px;
  }

  .difficulty-button.active {
    background-color: #7741ed; /* Active state background color */
  }
  
  .difficulty-button:hover {
    background-color: #6a6aa8; /* Brighter color on hover */
  }
  
  .difficulty-button:active,
  .difficulty-button:focus {
    background-color: #7741ed; /* Brightest color on click/selection */
  }
  
  .place-bet-button {
    flex:1;
    padding: 10px;
    margin-left: 25px;
    margin-right: 25px;
    margin-top: 10px;
    background-color: #d8b423;
    color: black;
    border: none;
    border-radius: 12px; /* Increased border radius */
    font-family: 'Comfortaa';
    font-weight:bolder;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .place-bet-button:hover {
    background-color: #ffd700; /* Brighter yellow on hover */
  }
  
  .place-bet-button:active,
  .place-bet-button:focus {
    background-color: #ffeb3b; /* Brightest yellow on click/selection */
  }
  
  .play-style {
    display: flex;
    margin-left: 20px;
    width:100%;
    flex-direction: row;
    justify-content: flex-start;
    background-color: #090c1d;
    padding-bottom: 20px;
  }

  .play-style-enclosure {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: row;
    border: 3px solid #191a39 ;
    border-radius: 18px;
    padding: 1px;
  }
  
  .play-style-button {
    flex:1;
    margin-top: 2.5px;
    margin-bottom: 2.5px;
    margin-left: 2px;
    margin-right: 2px;
    padding: 10px;
    background-color: #191a39;
    color: white;
    border: none;
    border-radius: 12px; /* Increased border radius */
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-family: 'Comfortaa';
    font-weight:bold;
    font-size: 14px;
  }
  
  .play-style-button:hover {
    background-color: #6a6aa8; /* Brighter color on hover */
  }
  
  .play-style-button.active {
    background-color: #7741ed; /* Brightest color on click/selection */
  }

  .play-style-button:active,
  .play-style-button:focus {
    background-color: #7741ed; /* Brightest color on click/selection */
  }

  .deposit-button {
    flex:0.6;
    height:42px;
    margin-top: 4px;
    margin-right: 4px;
    background-color: #ffd700;
    color: black;
    border: none;
    border-top-left-radius: 22px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 22px;
    border-bottom-right-radius: 0px;
    font-family: 'Comfortaa';
    font-weight: bolder;
    font-size: 17px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .deposit-button:hover {
    background-color: #fff06c; /* Brighter yellow on hover */
  }
  
  .deposit-button:active,
  .deposit-button:focus {
    background-color: #fff06c; /* Brightest yellow on click/selection */
  }

  .withdraw-button {
    flex:0.6;
    height:42px;
    margin-top: 4px;
    margin-right: 4px;
    background-color: #7741ed;
    color: white;
    border: none;
    border-top-right-radius: 22px;
    border-top-left-radius: 0px;
    border-bottom-right-radius: 22px;
    border-bottom-left-radius: 0px;
    font-family: 'Comfortaa';
    font-weight: bolder;
    font-size: 17px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .withdraw-button:hover {
    background-color: #a176ff; /* Brighter yellow on hover */
  }
  
  .withdraw-button:active,
  .withdraw-button:focus {
    background-color: #a176ff; /* Brightest yellow on click/selection */
  }
  

  .num-toss-input-1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height:40px;
    margin-top: 10px;
    width: 90px;
    align-self: center;
    background-color: #090c1d;
    border: 3px solid #191a39 ;
    border-radius: 30px; /* Increased border radius */
  }
  
  .num-toss-input-field-1 {
    width: 60px;
    background-color: #090c1d;
    color: white;
    border: none;
    border-radius: 25px; /* Increased border radius */
    text-align: center;
    align-self: center;
    font-family: 'Comfortaa';
    font-weight: bold;
    font-size: 16px;
    outline: none; /* Remove outline on focus */
  }
  
  .num-toss-input-field-1::-webkit-inner-spin-button,
  .num-toss-input-field-1::-webkit-outer-spin-button {
    -webkit-appearance: none; /* Remove default styles */
    margin: 0; /* Remove margin */
  }

  .session-gain {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height:40px;
    margin-top: 10px;
    width: 90px;
    align-self: center;
    background-color: #090c1d;
    border: 3px solid #191a39 ;
    border-radius: 30px; /* Increased border radius */
  }

  .session-gain.positive {
    background-color: #54c152;
  }

  .session-gain.negative {
    background-color: #fa4a4a;
  }
  
  .session-gain-span {
    width: 60px;
    background-color: #090c1d;
    color: white;
    border: none;
    border-radius: 25px; /* Increased border radius */
    text-align: center;
    align-self: center;
    font-family: 'Comfortaa';
    font-weight: bold;
    font-size: 16px;
    outline: none; /* Remove outline on focus */
  }

  .session-gain-span.positive {
    background-color: #54c152;
  }

  .session-gain-span.negative {
    background-color: #fa4a4a;
  }
  


  .auto-toss-pause-button {
    height:50px;
    width: 150px;
    margin-top: 4px;
    margin-right: 4px;
    background-color: #191a39;
    color: white;
    border: none;
    border-radius: 22px; /* Increased border radius */
    font-family: 'Comfortaa';
    font-weight: bolder;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .auto-toss-pause-button:hover {
    background-color: #6a6aa8; /* Brighter yellow on hover */
  }
  
  .auto-toss-pause-button:active,
  .auto-toss-pause-button:focus {
    background-color: #7741ed; /* Brightest yellow on click/selection */
  }
  
  